
import React from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import NewProjectButton from '@/components/NewProjectButton';
import StatusSection from './StatusSection';
import QuickTodo from './quick-todo/QuickTodo';

interface ProjectsGridProps {
  projects: Project[];
  onSuccess: () => void;
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({ projects, onSuccess }) => {
  // Group projects by status without additional sorting (they come pre-sorted by due date)
  const activeProjects = projects.filter(p => p.status === 'active');
  const inProgressProjects = projects.filter(p => p.status === 'in_progress');
  const queueProjects = projects.filter(p => p.status === 'queue');
  const blockedProjects = projects.filter(p => p.status === 'blocked');
  const completedProjects = projects.filter(p => p.status === 'completed');

  return (
    <div className="space-y-8">
      {/* Add Quick Todo component before In Progress projects */}
      <QuickTodo />
      
      <StatusSection 
        title="In Progress" 
        projects={inProgressProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Active" 
        projects={activeProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="In Queue" 
        projects={queueProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Blocked" 
        projects={blockedProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Completed" 
        projects={completedProjects} 
        onSuccess={onSuccess} 
      />
    </div>
  );
};

export default ProjectsGrid;
