
import React, { useState, useEffect } from 'react';
import { Clock, Plus } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface EstimatedTimeInputProps {
  minutes?: number | null;
  onUpdate?: (minutes: number | null) => Promise<void>;
  autoIncrement?: boolean;
}

const EstimatedTimeInput: React.FC<EstimatedTimeInputProps> = ({
  minutes,
  onUpdate,
  autoIncrement = false
}) => {
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [timeValue, setTimeValue] = useState(minutes?.toString() || '');
  const [previousValue, setPreviousValue] = useState(minutes);
  const [isValueIncreased, setIsValueIncreased] = useState(false);

  // Track value changes to highlight when incremented
  useEffect(() => {
    if (previousValue !== undefined && previousValue !== null && 
        minutes !== undefined && minutes !== null) {
      if (minutes > previousValue) {
        setIsValueIncreased(true);
        const timer = setTimeout(() => setIsValueIncreased(false), 3000);
        return () => clearTimeout(timer);
      }
    }
    setPreviousValue(minutes);
  }, [minutes, previousValue]);

  // Update local state when prop changes
  useEffect(() => {
    if (minutes !== undefined && minutes !== null) {
      setTimeValue(minutes.toString());
    }
  }, [minutes]);

  const handleSaveTime = async () => {
    if (onUpdate) {
      const minutes = timeValue.trim() === '' ? null : parseFloat(timeValue);
      
      if (timeValue.trim() !== '' && (isNaN(minutes!) || minutes! < 1)) {
        return; // Invalid input
      }
      
      await onUpdate(minutes);
      setIsEditingTime(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveTime();
    } else if (e.key === 'Escape') {
      setIsEditingTime(false);
      setTimeValue(minutes?.toString() || '');
    }
  };

  const formatTime = (minutes: number | null | undefined) => {
    if (!minutes) return null;
    
    if (autoIncrement) {
      // Show minutes and seconds for auto-incrementing tasks
      const mins = Math.floor(minutes);
      const secs = Math.round((minutes - mins) * 60);
      return `${mins}:${secs.toString().padStart(2, '0')} min`;
    } else {
      // Show only minutes for regular tasks
      return `${minutes} min`;
    }
  };

  if (isEditingTime) {
    return (
      <div className="flex items-center gap-1">
        <Clock className="h-3 w-3 flex-shrink-0 text-muted-foreground" />
        <Input
          type="number"
          min="1"
          step="0.1"
          className="h-6 w-16 text-xs px-1 py-0"
          value={timeValue}
          onChange={(e) => setTimeValue(e.target.value)}
          onBlur={handleSaveTime}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <span className="text-xs text-muted-foreground">min</span>
      </div>
    );
  }
  
  if (minutes) {
    return (
      <motion.div 
        className={cn(
          "flex items-center cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors",
          isValueIncreased ? "text-green-600 dark:text-green-400" : "text-muted-foreground"
        )}
        onClick={() => onUpdate && setIsEditingTime(true)}
        title="Click to edit estimated time"
        animate={isValueIncreased ? { 
          scale: [1, 1.05, 1],
          y: [0, -1, 0] 
        } : {}}
        transition={{ duration: 0.5 }}
      >
        <Clock className="h-3 w-3 mr-1" />
        {formatTime(minutes)}
        {autoIncrement && (
          <span className={cn(
            "ml-1 text-xs",
            isValueIncreased ? "text-green-500 font-medium" : "text-green-600/70 dark:text-green-500/70"
          )}>+1%</span>
        )}
      </motion.div>
    );
  }
  
  // Always visible, even when there's no value
  return (
    <div 
      className="flex items-center gap-1 text-muted-foreground cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors"
      onClick={() => onUpdate && setIsEditingTime(true)}
      title="Add estimated time"
    >
      <Clock className="h-3 w-3" />
      {onUpdate && <Plus className="h-3 w-3" />}
    </div>
  );
};

export default EstimatedTimeInput;
