import React, { useState } from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import { useNavigate } from 'react-router-dom';
import EditProjectDialog from './projects/EditProjectDialog';
import DeleteProjectDialog from './projects/DeleteProjectDialog';
import ProjectCardHeader from './projects/card/ProjectCardHeader';
import ProjectCardContent from './projects/card/ProjectCardContent';
import ProjectCardActions from './projects/card/ProjectCardActions';
import ProjectCardFooter from './projects/card/ProjectCardFooter';
import ProjectGoalInfo from './projects/card/ProjectGoalInfo';
import { useDoneForToday } from './projects/card/useDoneForToday';
import { Separator } from '@/components/ui/separator';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { StatusSwitchDialog } from './projects/card/StatusSwitchDialog';

interface ProjectCardProps {
  title: string;
  description: string;
  lastUpdated: string;
  priority?: 'asap' | 'high' | 'average' | 'low';
  dueDate?: Date;
  tags?: string[];
  status?: string;
  project?: Project;
  onSuccess?: () => void;
  onClick?: () => void;
  tasks?: number;
  completed?: number;
  isFavorite?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  priority = 'average',
  dueDate,
  tags = [],
  status = 'active',
  project,
  onSuccess,
  onClick,
  isFavorite,
}) => {
  const navigate = useNavigate();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const { handleDoneForToday } = useDoneForToday(project, onSuccess);
  const [localState, setLocalState] = useState({ refreshCounter: 0 });

  const handleRefresh = () => {
    setLocalState(prev => ({ refreshCounter: prev.refreshCounter + 1 }));
    if (onSuccess) onSuccess();
  };

  const handleCardClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).closest('.interactive-element')) {
      e.stopPropagation();
      return;
    }
    
    if (onClick) {
      onClick();
    } else if (project) {
      navigate(`/projects/${project.id}`);
    }
  };

  const handleStatusClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsStatusDialogOpen(true);
  };

  const updateStatus = async (newStatus: string) => {
    if (!project) return;
    
    try {
      const { error } = await supabase
        .from('projects')
        .update({ status: newStatus })
        .eq('id', project.id);

      if (error) throw error;
      
      toast.success(`Project status updated to ${newStatus}`);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error('Error updating project status:', error);
      toast.error('Failed to update project status');
    } finally {
      setIsStatusDialogOpen(false);
    }
  };

  const projectTags = project?.tags || tags;
  const projectGoals = project?.goals || [];

  return (
    <>
      <div 
        className="glass rounded-lg overflow-hidden hover-lift cursor-pointer transition-all duration-300 w-full max-w-3xl" 
        onClick={handleCardClick}
      >
        <div className="p-5 space-y-4">
          <ProjectCardHeader
            title={title}
            status={status}
            recurrence={project?.recurrence}
            isFavorite={isFavorite}
            onStatusClick={handleStatusClick}
            onEditClick={(e) => {
              e.stopPropagation();
              setIsEditDialogOpen(true);
            }}
            onDeleteClick={(e) => {
              e.stopPropagation();
              setIsDeleteDialogOpen(true);
            }}
          />

          {projectGoals && projectGoals.length > 0 && (
            <ProjectGoalInfo goals={projectGoals} />
          )}

          <ProjectCardContent 
            description={description} 
            tags={projectTags}
          />

          {project && (
            <ProjectCardActions
              project={project}
              onProjectUpdated={handleRefresh}
            />
          )}
        </div>

        <Separator />

        <ProjectCardFooter
          dueDate={dueDate}
          onDoneClick={(e) => {
            e.stopPropagation();
            handleDoneForToday(e);
          }}
        />
      </div>

      {project && (
        <>
          <EditProjectDialog 
            project={project} 
            isOpen={isEditDialogOpen} 
            onClose={() => setIsEditDialogOpen(false)} 
            onSuccess={onSuccess || (() => {})} 
          />
          
          <DeleteProjectDialog
            project={project}
            isOpen={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onSuccess={onSuccess || (() => {})}
          />

          <StatusSwitchDialog
            currentStatus={status}
            isOpen={isStatusDialogOpen}
            onClose={() => setIsStatusDialogOpen(false)}
            onStatusChange={updateStatus}
          />
        </>
      )}
    </>
  );
};

export default ProjectCard;
