
import React, { useState, useRef, useEffect } from 'react';
import { Clock } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

/**
 * Props for the HabitTime component
 */
interface HabitTimeProps {
  /** Current time in minutes for the habit */
  minutes: number | null | undefined;
  /** Callback when the time is edited */
  onEditTime: (minutes: number) => void;
  /** Whether to enable auto-increment feature (adds 1% indicator) */
  autoIncrement?: boolean;
}

/**
 * Component for displaying and editing the time duration of a habit.
 * 
 * Features:
 * - Displays formatted time (minutes for regular habits, minutes:seconds for auto-increment)
 * - Inline editing with focused input
 * - Different display formats based on habit type
 * - Visual indicator for auto-incrementing habits
 * 
 * @param minutes - Current time value in minutes
 * @param onEditTime - Callback function when time is changed
 * @param autoIncrement - Whether the habit uses auto-increment feature
 */
export function HabitTime({ minutes, onEditTime, autoIncrement = false }: HabitTimeProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTime, setEditedTime] = useState(minutes?.toString() || '');
  const [previousValue, setPreviousValue] = useState(minutes);
  const [isValueIncreased, setIsValueIncreased] = useState(false);
  const timeInputRef = useRef<HTMLInputElement>(null);

  // Focus and select the input when entering edit mode
  useEffect(() => {
    if (isEditing && timeInputRef.current) {
      timeInputRef.current.focus();
      timeInputRef.current.select();
    }
  }, [isEditing]);

  // Track value changes to highlight when incremented
  useEffect(() => {
    if (previousValue !== undefined && previousValue !== null && 
        minutes !== undefined && minutes !== null) {
      if (minutes > previousValue) {
        setIsValueIncreased(true);
        const timer = setTimeout(() => setIsValueIncreased(false), 3000);
        return () => clearTimeout(timer);
      }
    }
    setPreviousValue(minutes);
  }, [minutes, previousValue]);

  // Update local state when prop changes
  useEffect(() => {
    if (minutes !== undefined && minutes !== null) {
      setEditedTime(minutes.toString());
    }
  }, [minutes]);

  /**
   * Submits the edited time value
   */
  const handleSubmit = () => {
    onEditTime(parseFloat(editedTime));
    setIsEditing(false);
  };

  /**
   * Handles keyboard events for form submission and cancellation
   */
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Escape') {
      setEditedTime(minutes?.toString() || '');
      setIsEditing(false);
    }
  };

  /**
   * Formats time display based on habit type (regular vs auto-increment)
   * - For auto-increment: displays as minutes:seconds
   * - For regular: displays as whole number or decimal minutes
   */
  const formatTime = (minutes: number | null | undefined) => {
    if (!minutes) return null;
    
    if (autoIncrement) {
      // Show minutes and seconds for auto-incrementing habits
      const mins = Math.floor(minutes);
      const secs = Math.round((minutes - mins) * 60);
      return `${mins}:${secs.toString().padStart(2, '0')} min`;
    } else {
      // Show only minutes for regular habits
      // If it's a whole number, display it as an integer
      return Number.isInteger(minutes) ? `${minutes} min` : `${minutes.toFixed(2)} min`;
    }
  };

  // Render edit mode with input field
  if (isEditing) {
    return (
      <div className="flex items-center gap-1">
        <Clock className="h-4 w-4 mr-1" />
        <Input
          ref={timeInputRef}
          type="number"
          value={editedTime}
          onChange={(e) => setEditedTime(e.target.value)}
          onBlur={handleSubmit}
          onKeyDown={handleKeyDown}
          className="w-16 h-7 py-0 px-1 text-sm"
          min="0.1"
          step="0.1"
        />
        <span className="text-sm">min</span>
      </div>
    );
  }

  // Render display mode with tooltip
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <motion.div 
            className={cn(
              "flex items-center cursor-pointer hover:bg-accent/50 rounded p-1 relative",
              isValueIncreased ? "text-green-600 dark:text-green-400" : "text-muted-foreground"
            )}
            onClick={() => setIsEditing(true)}
            animate={isValueIncreased ? { 
              scale: [1, 1.05, 1],
              y: [0, -1, 0] 
            } : {}}
            transition={{ duration: 0.5 }}
          >
            <Clock className="h-4 w-4 mr-1" />
            <span>
              {formatTime(minutes) || "Set time"}
              {minutes && autoIncrement && (
                <span className={cn(
                  "ml-1 text-xs",
                  isValueIncreased ? "text-green-500 font-medium" : "text-green-600/70 dark:text-green-500/70"
                )}>+1%</span>
              )}
            </span>
          </motion.div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Click to edit time{autoIncrement ? ", auto-increases by 1% on completion" : ""}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
