
/**
 * Utility functions for working with time-of-day values in the application.
 * These utilities help standardize how time periods are represented and formatted.
 */

import { TimeOfDay } from '@/types/habit';

/**
 * Returns a human-readable time range label for a given time of day.
 * Used for displaying when habits should be performed.
 * 
 * @param timeOfDay - The time of day category ('morning', 'afternoon', 'evening', or 'night')
 * @returns A formatted string representing the time range (e.g., "5am-12pm")
 */
export const getTimeRangeLabel = (timeOfDay: TimeOfDay): string => {
  switch (timeOfDay) {
    case 'morning':
      return '5am-12pm';
    case 'afternoon':
      return '12pm-5pm';
    case 'evening':
      return '5pm-10pm';
    case 'night':
      return '10pm-5am';
    default:
      return '';
  }
};

/**
 * Determines the current time of day based on the current time.
 * Useful for highlighting relevant habits for the current period.
 * 
 * @returns The current time of day category
 */
export const getCurrentTimeOfDay = (): TimeOfDay => {
  const hour = new Date().getHours();
  
  if (hour >= 5 && hour < 12) {
    return 'morning';
  } else if (hour >= 12 && hour < 17) {
    return 'afternoon';
  } else if (hour >= 17 && hour < 22) {
    return 'evening';
  } else {
    return 'night';
  }
};

/**
 * Checks if a habit is scheduled for the current day of the week.
 * 
 * @param daysOfWeek - Array of days when the habit should be performed
 * @returns true if the habit should be performed today, false otherwise
 */
export const isHabitScheduledForToday = (daysOfWeek: string[] | null): boolean => {
  // If no days specified, habit is for every day
  if (!daysOfWeek || daysOfWeek.length === 0) {
    return true;
  }
  
  const daysMap: Record<number, string> = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'
  };
  
  const today = new Date().getDay();
  const todayString = daysMap[today];
  
  return daysOfWeek.includes(todayString);
};

/**
 * Formats a time value in minutes to a human-readable string.
 * 
 * @param minutes - Time value in minutes
 * @param includeSeconds - Whether to include seconds in the output
 * @returns Formatted time string (e.g., "1h 30m" or "1:30:00")
 */
export const formatTime = (minutes: number, includeSeconds: boolean = false): string => {
  if (minutes < 0) return '0m';
  
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  
  if (includeSeconds) {
    const secs = Math.round((minutes - Math.floor(minutes)) * 60);
    return `${hours > 0 ? hours + ':' : ''}${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }
  
  if (hours === 0) return `${mins}m`;
  return `${hours}h ${mins > 0 ? mins + 'm' : ''}`;
};

/**
 * Checks if a habit was completed today based on its last_completed timestamp.
 * 
 * @param lastCompleted - ISO timestamp of when the habit was last completed
 * @returns true if the habit was completed today, false otherwise
 */
export const wasCompletedToday = (lastCompleted: string | null): boolean => {
  if (!lastCompleted) return false;
  
  const lastCompletedDate = new Date(lastCompleted);
  const today = new Date();
  
  return (
    lastCompletedDate.getDate() === today.getDate() &&
    lastCompletedDate.getMonth() === today.getMonth() &&
    lastCompletedDate.getFullYear() === today.getFullYear()
  );
};
